import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { HomeQuery } from "../../graphql-types";
import Markets from "../components/pages/markets/markets";

type IndexPageProps = PageProps<HomeQuery>;

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Coinomics - Cryptocurrency and DeFi stats, prices and market insights"
        description="Cryptocurrency and DeFi market overview, prices, market capitalization, charts and analysis."
        isNoTitleSuffix={true}
      />

      <Markets staticData={data} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query Home {
    allCoinListItem(
      limit: 6
      sort: { fields: [market_cap_rank], order: [ASC] }
    ) {
      nodes {
        id
        slug
        symbol
        name
        image
        current_price
        price_change_percentage_24h
      }
    }
    allExchangeListItem(
      limit: 6
      sort: { fields: [trust_score_rank], order: [ASC] }
    ) {
      nodes {
        id
        slug
        name
        image
        trade_volume_24h_btc
      }
    }
  }
`;
